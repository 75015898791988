import gql from "graphql-tag";

export const FIELD_QUERY = gql`
  query($farmerId: ID) {
    fields(farmerId:$farmerId) {
      edges{
        node{
          id
          name
          prefecture
          city
          address
          lat
          lon
          sectionsSet {
            edges {
              node {
                id
                name
                rowsSet {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
