<template>
  <div v-if="$apollo.loading"></div>
  <div v-else>
    <b-form-group>
      <b-form-select :plain="true" v-model="field" v-on:change="selected">
        <option
          v-for="item in this.fields.edges"
          :key="item.node.id"
          :value="item.node"
        >{{item.node.name}}</option>
        <option v-if="checkDelete" :value="null">{{"(削除済み)"}}</option>
      </b-form-select>
    </b-form-group>
  </div>
</template>


<script>
import { FIELD_QUERY } from "../../../components/field-query";
import { allSettled, allResolved } from "q";

const DEFAULT_INDEX = 0;
const DEFAULT_FARMER_ID = "RmFybWVyTm9kZTox";

export default {
  data() {
    return {
      currentFarmer: [],
      fields: [],
      field: null,
      checkDelete: false
    };
  },
  props: {
    propField: {
      default: null
    }
  },
  methods: {
    selected() {
      this.$emit("getField", this.field);
    }
  },
  apollo: {
    fields: {
      query: FIELD_QUERY,
      variables: {
        farmerId: DEFAULT_FARMER_ID
      },
      result({ data, loading }) {
        if (data) {
          if (this.propField) {
            for (let i = 0; i < this.fields.edges.length; i++) {
              if (this.fields.edges[i].node.id == this.propField.id) {
                this.field = this.fields.edges[i].node;
              }
            }
          } else {
            this.field = this.fields.edges[DEFAULT_INDEX].node;
          }

          if (!this.field) {
            this.checkDelete = true;
          }
          this.$emit("getField", this.field);
        }
      },
      error(error) {
        console.error("Field", error);
      }
    }
  }
};
</script>
